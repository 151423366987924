<template>
  <div class="flex cursor-pointer items-center">
    <div class="flex flex-col">
      <SvgIcon
        v-if="isMobileMenuShown"
        name="close"
        class="icons-medium cursor-default md:cursor-pointer"
        @click="emit('close')"
      />
      <SvgIcon
        v-else
        name="align-justify"
        class="icons-medium cursor-default md:cursor-pointer"
        @click="emit('open')"
      />
    </div>
  </div>
</template>

<script setup>
const { isMobileMenuShown } = storeToRefs(useMenuStore());
const emit = defineEmits(['open', 'close']);
</script>

<style lang="scss"></style>
