<template>
  <div class="flex">
    <NuxtLink
      to="/"
      class="-mb-2 flex h-full w-42 justify-center"
      title="История.РФ"
    >
      <SvgIcon name="logo-light" class="h-20 w-full" />
    </NuxtLink>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
svg {
  @apply h-[70px] mb-[10px] md:h-[80px] md:mb-[0px];
}
</style>
