<template>
  <SvgIcon
    name="square-socials/email-n"
    class="size-6 text-white-100 hover:text-new-red-dark"
    @click="showModalFeedbackForm"
  />
</template>

<script setup>
const authStore = useAuthStore();
const modalsStore = useModalsStore();
const showModalFeedbackForm = () => {
  if (!authStore.isAuthorized) {
    modalsStore.showSocialAuthForm();
    return;
  }
  modalsStore.showFeedbackForm();
};
</script>
