<template>
  <nav ref="target" class="flex h-full flex-col items-start md:flex-row">
    <div
      v-for="item in menuItems"
      :key="item.id"
      class="mb-5 flex flex-col last:mb-0"
    >
      <Component
        :is="item.nested ? 'div' : NuxtLink"
        class="flex cursor-pointer items-center"
        :to="item.href"
        @click="item.nested ? toggleNestedItems(item) : ''"
      >
        <SvgIcon v-if="item.icon" :name="item.icon" class="size-4" />
        <span class="whitespace-nowrap px-[8px]">{{ item.title }}</span>
        <SvgIcon
          v-if="item.nested?.length"
          :name="
            expandedMenuItems.includes(item.id) ? 'chevron-up' : 'chevron-down'
          "
          class="cursor-pointer"
        />
      </Component>
      <div
        v-if="item.nested && expandedMenuItems.includes(item.id)"
        class="ml-[24px] mt-[12px] flex flex-col gap-[12px] text-[16px]"
      >
        <NuxtLink
          v-for="subItem in item.nested"
          :key="`subItem-${subItem.id}`"
          :to="subItem.href"
          >{{ subItem.title }}</NuxtLink
        >
      </div>
    </div>

    <div
      class="mt-1 flex flex-col border-t border-new-card--border pt-[24px] font-roboto font-medium"
    >
      <CommonLeftMenuBanner />
    </div>
  </nav>
</template>

<script setup>
import { navigationItemsNew } from '@/assets/data/navigation';
const NuxtLink = resolveComponent('NuxtLink');

const route = useRoute();
const menuStore = useMenuStore();

const target = ref(null);
const expandedMenuItems = ref([]);
const currentNested = ref(null);

const menuItems = computed(() => navigationItemsNew);

watch(
  () => route.path,
  () => {
    closeMenu();
  },
);

onClickOutside(target, (event) => {
  console.log(event), closeMenu();
});

const closeMenu = () => {
  menuStore.closeMobileMenu();
  currentNested.value = null;
};

const toggleNestedItems = (item) => {
  if (!item.nested?.length) {
    return;
  }

  if (expandedMenuItems.value.includes(item.id)) {
    expandedMenuItems.value = expandedMenuItems.value.filter(
      (id) => id !== item.id,
    );
  } else {
    expandedMenuItems.value.push(item.id);
  }
};

/*
// import TagsBlock from '@/components/Common/Blocks/TagsBlock'
import LeftMenuBanner from '@/components/Common/LeftMenuBanner';
import NestedBlock from '@/components/Navigation/NestedBlock';
import getScreenBreakpoint from '@/mixins/getScreenBreakpoint';
import randomArticle from '@/mixins/randomArticle';
import api from '@/services/api/api';
*/
/*
export default {
  components: {
    NestedBlock,
    // TagsBlock,
    LeftMenuBanner,
  },

  mixins: [getScreenBreakpoint, randomArticle],

  data() {
    return {
      tagsList: [],
      currentNested: {},
      showSubMenu: false,
      randomArticle: null,
      randomVideo: null,
      randomAudio: null,
      expandedMenuItems: [],
    };
  },
  },

  methods: {
    toggleAdditionalSubMenu() {
      this.showSubMenu = !this.showSubMenu;
      this.currentNested = '';
    },

    openNestedSubMenu(id) {
      this.showSubMenu = true;
      this.currentNested = id;
    },

    closeMenu() {
      this.showSubMenu = false;
      this.currentNested = '';
    },

    isNestedItemCurrentPage(nestedList) {
      return nestedList.map((el) => el.href).includes(this.$route.path);
    },

    toggleNestedItems(item) {
      if (!item.nested?.length) {
        return;
      }

      if (this.expandedMenuItems.includes(item.id)) {
        this.expandedMenuItems = this.expandedMenuItems.filter(
          (id) => id !== item.id,
        );
      } else {
        this.expandedMenuItems.push(item.id);
      }
    },
  },
};
*/
</script>

<style lang="scss"></style>
