<template>
  <div
    class="custom-scrollbar fixed inset-0 z-100 flex items-center justify-center bg-black-84"
    :class="!isSearch && 'overflow-y-scroll'"
    @click.self="isSearch && modalsStore.closeModal()"
  >
    <div
      class="z-100"
      :class="[
        { 'w-full': isFull },
        isSearch
          ? 'absolute inset-x-0 top-0'
          : 'relative max-h-dvh max-w-[846px] ',
      ]"
      :style="isMobile || isSearch ? '' : ''"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
defineProps({
  isSearch: {
    type: Boolean,
    default: false,
  },
  isFull: {
    type: Boolean,
    default: false,
  },
});
const modalsStore = useModalsStore();

const { isMobile } = useBreakpoint();
/*
import getScreenBreakpoint from '@/mixins/getScreenBreakpoint';

export default {
  mixins: [getScreenBreakpoint],
  props: {
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
};*/
</script>

<style lang="scss"></style>
