<template>
  <ModalsAuthorizationAuthModalWrapper>
    <div class="flex flex-col lg:min-w-[736px]">
      <h6 class="mb-16 font-roboto text-[31px] font-medium leading-[38px]">
        Регистрация на портале
        <span class="text-ruby-red-100">“История.рф”</span>
      </h6>
      <form>
        <BaseTheInput
          v-model="formData.email"
          placeholder="Введите ваш e-mail"
          is-redesign-auth
          type="email"
          class="mb-4"
          :error="v$.email.$errors[0]?.$message"
        />
        <BaseTheInput
          v-model="formData.name"
          placeholder="Введите ваше имя"
          is-redesign-auth
          class="mb-4"
          :error="v$.name.$errors[0]?.$message"
        />
        <BaseTheInput
          v-model="formData.password"
          placeholder="Введите ваш пароль"
          type="password"
          is-redesign-auth
          :error="v$.password.$errors[0]?.$message"
        />
      </form>
      <BaseTheButtonNew
        text="Зарегистрироваться"
        type="secondary"
        class="mt-12 max-h-[64px] w-full justify-center sm:w-full"
        @click="proceedRegistration"
      />
    </div>
  </ModalsAuthorizationAuthModalWrapper>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, minLength, required } from '@vuelidate/validators';

import { validationErrors } from '@/assets/data/validateErrors';

const authStore = useAuthStore();
const toast = useToast();

const formData = ref({
  email: null,
  password: null,
  name: null,
});

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(validationErrors.required, required),
      email: helpers.withMessage(validationErrors.email, email),
    },
    password: {
      required: helpers.withMessage(validationErrors.required, required),
      minLength: helpers.withMessage(
        validationErrors.passwordMinLength,
        minLength(8),
      ),
    },
    name: {
      required: helpers.withMessage(validationErrors.required, required),
    },
  };
});

const v$ = useVuelidate(rules, formData);

const proceedRegistration = () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return 0;
  }

  const user = {
    email: formData.value.email,
    name: formData.value.name,
    password: formData.value.password,
    password_confirmation: formData.value.password,
  };

  try {
    authStore.register(user);
  } catch (e) {
    toast.error(e);
  }
};
</script>

<style lang="scss"></style>
