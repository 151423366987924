<template>
  <button
    class="flex size-12 items-center justify-center rounded-full bg-new-black text-white-100"
    @click="scrollToTop"
  >
    <SvgIcon name="arrow-top" />
  </button>
</template>

<script setup>
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};
</script>
