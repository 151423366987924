<template>
  <ModalsAuthorizationAuthModalWrapper>
    <div class="flex flex-col lg:min-w-[736px]">
      <h6
        class="text-center font-roboto text-[31px] font-medium leading-[38px]"
      >
        Вход в личный кабинет на портале
        <span class="text-ruby-red-100">“История.рф”</span>
      </h6>
      <div
        class="mx-auto mt-20 font-roboto text-[18px] font-medium leading-[22px]"
      >
        Войти через
      </div>
      <ModalsAuthorizationSocialAuthList class="mt-7" />
      <!-- <the-button
        text="или продолжить через почту"
        @click.native="$store.dispatch('modal/showEmailAuthForm')"
      /> -->
      <ModalsAuthorizationEmailAuthBtn
        @click="modalsStore.showEmailAuthForm()"
      />

      <div
        class="mx-auto my-10 font-roboto text-[18px] font-medium leading-[22px]"
      >
        или
      </div>
      <BaseTheButtonNew
        class="max-h-[64px] w-full justify-center sm:w-full"
        text="зарегистрироваться"
        @click="modalsStore.showRegistrationForm()"
      />
    </div>
  </ModalsAuthorizationAuthModalWrapper>
</template>

<script setup>
const modalsStore = useModalsStore();
</script>

<style lang="scss"></style>
