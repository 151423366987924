<template>
  <div
    class="search-button transition-effect flex cursor-pointer items-center justify-center font-bold text-white-100 hover:text-white-56"
    @click="modalsStore.showSearch()"
  >
    <SvgIcon
      name="search"
      class="w-4 md:mr-2"
      :class="{ 'icons-medium': isMobile }"
    />
    <span class="text-smaller-caps hidden md:block">Поиск</span>
  </div>
</template>

<script setup>
const { isMobile } = useBreakpoint();
const modalsStore = useModalsStore();
</script>

<style lang="scss"></style>
