<template>
  <ModalsAuthorizationAuthModalWrapper>
    <div class="mt-8 flex flex-col lg:min-w-[736px]">
      <BaseTheInput
        v-model="emailRef"
        placeholder="Введите ваш e-mail"
        is-redesign-auth
        type="email"
        :error="v$.email.$errors[0]?.$message"
      />
      <BaseTheButtonNew
        text="Восстановить пароль"
        type="secondary"
        class="mb-5 mt-12 max-h-[64px] w-full justify-center sm:w-full"
        @click="resetPassword"
      />
      <BaseTheButtonNew
        text="Вернуться к авторизации"
        class="mb-8 max-h-[64px] w-full justify-center sm:w-full"
        @click="modalsStore.showEmailAuthForm()"
      />
    </div>
  </ModalsAuthorizationAuthModalWrapper>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';

import { validationErrors } from '@/assets/data/validateErrors';

const authStore = useAuthStore();
const modalsStore = useModalsStore();
const toast = useToast();
const emailRef = ref(null);

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(validationErrors.required, required),
      email: helpers.withMessage(validationErrors.email, email),
    },
  };
});

const v$ = useVuelidate(rules, { email: emailRef });
const resetPassword = () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return 0;
  }

  try {
    authStore.forgotPassword({
      email: emailRef.value,
    });
  } catch (e) {
    toast.error(e);
  }
};
</script>

<style lang="scss"></style>
