<template>
  <ModalsTheModal>
    <div
      class="relative flex flex-col bg-white-100 p-4 shadow-drop-24 md:rounded-2xl md:p-14"
    >
      <div
        class="absolute right-2 top-2 flex cursor-pointer items-center"
        @click="modalsStore.closeModal()"
      >
        <SvgIcon name="close" class="icons-medium" />
      </div>
      <header class="flex justify-between">
        <div
          v-if="isPasswordRecovery"
          class="font-roboto text-[32px] font-medium leading-[38px]"
        >
          Восстановление пароля
        </div>
        <!-- <tabs
          v-else
          :list="tabs"
          :initial-tab-index="getCurrentTabIndex"
          @onChange="handleChangeTab"
        /> -->
      </header>
      <slot></slot>
      <!-- <footer class="mt-14 text-center text-smaller text-black-100">
        Авторизуясь или регистрируясь, вы даете
        <a
          href="https://histrf.ru/uploads/media/default/0001/47/5491158e052d1a6419969f91e6effa7fafd60ade.pdf"
          target="_blank"
          class="text-blue-ryb-100"
          >согласие на обработку персональных данных</a
        >.
      </footer> -->
    </div>
  </ModalsTheModal>
</template>

<script setup>
const modalsStore = useModalsStore();

// const activeTabIndex = ref(0);
// const login = ['SocialAuthForm', 'EmailAuthForm'];
// const registration = ['RegistrationForm'];
// const tabs = [
//   { id: 0, name: 'Вход', model: 'login' },
//   {
//     id: 1,
//     name: 'Регистрация',
//     model: 'registration',
//   },
// ];

const getShownModalName = computed(() => {
  return modalsStore.getShownModal;
});

const isPasswordRecovery = computed(() => {
  return ['ResetPasswordForm', 'NewPasswordForm'].includes(
    getShownModalName.value,
  );
});

// const getCurrentTabIndex = computed(() => {
//   switch (getShownModalName.value) {
//     case 'SocialAuthForm':
//     case 'EmailAuthForm':
//       return 0;
//     case 'RegistrationForm':
//       return 1;
//     default:
//       return undefined;
//   }
// });

// const handleChangeTab = (e) => {
//   if (e === 'login') {
//     modalsStore.showEmailAuthForm();
//   } else if (e === 'registration') {
//     modalsStore.showRegistrationForm();
//   }
// };
/*
// import Tabs from '@/components/Common/Tabs'
import TheModal from '@/components/Modals/TheModal';
export default {
  components: {
    // Tabs,
    TheModal,
  },

  data() {
    return {
      activeTabIndex: 0,
      login: ['SocialAuthForm', 'EmailAuthForm'],
      registration: ['RegistrationForm'],
      tabs: [
        { id: 0, name: 'Вход', model: 'login' },
        {
          id: 1,
          name: 'Регистрация',
          model: 'registration',
        },
      ],
    };
  },

  computed: {
    getShownModalName() {
      return this.$store.getters['modal/getShownModal'];
    },

    isPasswordRecovery() {
      return ['ResetPasswordForm', 'NewPasswordForm'].includes(
        this.getShownModalName,
      );
    },

    getCurrentTabIndex() {
      switch (this.getShownModalName) {
        case 'SocialAuthForm':
        case 'EmailAuthForm':
          return 0;
        case 'RegistrationForm':
          return 1;
        default:
          return undefined;
      }
    },
  },

  methods: {
    handleChangeTab(e) {
      if (e === 'login') {
        this.$store.dispatch('modal/showEmailAuthForm');
      } else if (e === 'registration') {
        this.$store.dispatch('modal/showRegistrationForm');
      }
    },
  },
};*/
</script>

<style lang="scss"></style>
