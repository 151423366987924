<template>
  <div class="flex flex-col justify-center">
    <a
      v-for="social in socials"
      :key="`social-auth-${social.id}`"
      :href="social.authLink"
      class="transition-effect mb-7 flex items-center rounded-lg text-white-100 hover:opacity-90"
      :style="{ background: social.color }"
    >
      <div class="grid size-[64px] place-items-center rounded-lg bg-black-10">
        <SvgIcon :name="social.icon" class="icons-big" />
      </div>
      <div class="ml-[-64px] flex grow justify-center">
        {{ social.name }}
      </div>
    </a>
  </div>
</template>

<script setup>
const socials = [
  // {
  //   id: 0,
  //   icon: 'socials/google',
  //   authLink: 'https://histrf.ru/api/v1/login/google',
  //   name: 'Google',
  //   color: '#4c8aff',
  // },
  {
    id: 1,
    icon: 'socials/vkid',
    authLink: 'https://histrf.ru/api/v1/login/vkontakte',
    name: 'Войти через VK ID',
    color: '#0077FF',
  },
  {
    id: 2,
    icon: 'socials/yandex',
    authLink: 'https://histrf.ru/api/v1/login/yandex',
    name: 'Яндекс',
    color: '#F74F25',
  },
  // {
  //   id: 2,
  //  icon: 'socials/vk',
  //   authLink: 'https://histrf.ru/api/v1/login/vkontakte',
  // },
  // {
  //   id: 3,
  //   icon: 'socials/facebook',
  //   authLink: 'https://histrf.ru/api/v1/login/facebook',
  // },
  {
    id: 4,
    icon: 'socials/ok',
    authLink: 'https://histrf.ru/api/v1/login/odnoklassniki',
    name: 'Одноклассники',
    color: '#EE8208',
  },
  // { id: 5, icon: 'socials/twitter', authLink: 'https://histrf.ru/api/v1/login/twitter' },
];
</script>

<style lang="scss"></style>
