<template>
  <div>
    <form class="w-full">
      <BaseTheInput
        v-model="email"
        is-inverse
        is-redesign-footer
        type="text"
        btn-text="Подписаться"
        :placeholder="
          isMobile ? 'e-mail' : 'Подпишитесь на новые публикации по e-mail'
        "
        @on-click="handleSubscribe"
      />
    </form>
  </div>
</template>

<script setup>
import api from '@/services/api/api';

const { isMobile } = useBreakpoint();
const toast = useToast();

const email = ref(null);

const handleSubscribe = async () => {
  if (email.value) {
    try {
      await api.subscription.subscribeByEmail(email.value);
      toast.success('Вы успешно подписались на рассылку!');
      email.value = null;
    } catch (e) {
      if (
        e?.response?.data?.errors?.email[0] ===
        'The email has already been taken.'
      ) {
        toast.error('Данный e-mail уже присутствует в базе подписок!');
      } else {
        toast.error('Возникла ошибка, попробуйте еще раз!');
      }
    }
  } else {
    toast.error('Введите e-mail!');
  }
};
</script>

<style lang="scss"></style>
