<template>
  <div class="relative flex items-center">
    <NuxtLink to="/profile" class="relative flex items-center">
      <CommonUserAvatar
        v-if="avatar"
        form="square"
        class="cursor-pointer"
        :path="avatar"
      />
      <CommonCounter v-if="false" />
    </NuxtLink>
  </div>
</template>

<script setup>
const authStore = useAuthStore();

const userInfo = computed(() => authStore.getUserInfo);

const avatar = computed(() => {
  return userInfo.value?.image && userInfo.value.image?.preview
    ? userInfo.value.image?.preview
    : userInfo.value?.sex === 'f'
    ? '/imgs/avatar-f.png'
    : userInfo.value?.sex === 'm'
    ? '/imgs/avatar-m.png'
    : '/imgs/blank-avatar-new_min.png';
});
</script>

<style lang="scss"></style>
