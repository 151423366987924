<template>
  <header
    v-show="isLoaded"
    class="header"
    :class="[
      // { 'header--hide': isScrolledDown && newOffset !== 0 },
      landing
        ? 'top-0 h-auto bg-white-82'
        : 'min-h-[80px] border-b bg-white-100',
      (!isAndroid && !isIos) || landing ? 'top-0' : 'top-16 lg:top-0',
    ]"
    :style="{ zIndex: 60 }"
  >
    <!-- getZIndex -->
    <div class="container h-full 2.5xl:max-w-[1920px]">
      <div class="grid grid-cols-7 items-center gap-8 xl:grid-cols-12">
        <div class="col-span-2 flex items-center justify-start xl:hidden">
          <div class="relative mr-7 flex items-center justify-center xl:hidden">
            <CommonBurger
              class="text-white-100"
              @open="openMobileMenu"
              @close="closeMobileMenu"
            />
            <CommonCounter v-if="false" class="left-full top-1/4" />
          </div>
          <a
            href="https://histrf-en.com"
            class="mr-7 block text-[16px] font-bold leading-[16px] text-white-100 xl:hidden"
          >
            En
          </a>
        </div>
        <CommonLogo class="col-span-3 justify-self-center xl:col-span-2" />
        <div
          class="col-span-2 flex items-center justify-end"
          :class="
            isMainPage || (!isMainPage && isLeftMenu)
              ? 'xl:col-span-10'
              : 'xl:col-span-2'
          "
        >
          <CommonSocials v-if="isDesktop" class="mr-7 xl:mr-10" />
          <a
            href="https://histrf-en.com"
            class="text-smaller-caps mr-7 hidden font-bold text-white-100 xl:block"
          >
            EN
          </a>

          <HeaderSearchButton
            class="mr-7 cursor-default md:cursor-pointer xl:mr-10"
          />
          <div>
            <HeaderProfileMenu v-if="isAuthorized" />
            <SvgIcon
              v-else
              name="user"
              class="icons-medium cursor-default md:cursor-pointer"
              @click="modalsStore.showSocialAuthForm()"
            />
          </div>

          <div
            v-if="isMobileMenuShown"
            class="custom-scrollbar absolute flex flex-col bg-white-100 p-5 shadow-drop-0 xl:static xl:flex-row xl:items-center xl:p-0"
            :class="[{ 'header__mobile-menu': isNotDesktop }]"
          >
            <NavigationMainNew
              v-if="isNotDesktop"
              class="md:flex-col xl:flex-row"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
// import { banners } from '@/assets/data/banners';

defineProps({
  landing: {
    type: Boolean,
    default: false,
  },

  isAndroid: {
    type: Boolean,
    default: false,
  },

  isIos: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const modalsStore = useModalsStore();
const menuStore = useMenuStore();
const { isAuthorized } = storeToRefs(useAuthStore());
const { isMobileMenuShown } = storeToRefs(menuStore);
const { isNotMobile, isDesktop, isNotDesktop } = useBreakpoint();

const isLoaded = ref(false);
const lastOffset = ref(0);
const newOffset = ref(0);
const currentLangId = ref(0);
const lang = ref([
  { id: 1, label: 'RU', href: 'https://histrf.ru/' },
  { id: 2, label: 'EN', href: 'https://histrf-en.com/' },
]);

const isMainPage = computed(() => {
  return route.path === '/';
});

const isLeftMenu = computed(() => {
  return route.name && isNotMobile.value; // .startsWith('read')
});

watch(
  () => route.path,
  () => (lastOffset.value = 0),
);

watch(currentLangId, () => {
  const langObj = lang.value.find((el) => el.id === currentLangId.value);
  window.location.replace(langObj.href);
});

onMounted(() => {
  isLoaded.value = true;
  window.addEventListener('scroll', handleScroll);
});

const handleScroll = () => {
  lastOffset.value = newOffset.value;
  newOffset.value = window.pageYOffset;
};

const openMobileMenu = () => {
  menuStore.openMobileMenu();
  document.body.style.position = 'fixed';
};

const closeMobileMenu = () => {
  menuStore.closeMobileMenu();
  document.body.style.position = '';
};
</script>

<style lang="scss">
.header {
  @apply bg-[#1f1f1f];
  z-index: 1;
  @apply fixed w-full h-full max-h-[80px];
  @apply border-black-16;
  @apply transition-all duration-200 ease-linear;
  @media (max-width: 767px) {
    z-index: 60;
  }

  &--hide {
    transform: translateY(-80px);
  }

  &__mobile-menu {
    top: 88px;
    left: 8px;
    max-width: 300px;
    min-width: 270px;
    width: 70vw;
    @apply rounded-[12px] bg-white-100 shadow-drop-0 max-h-[calc(100vh-212px)] overflow-y-auto;
  }
}
.banner-opener {
  @apply bg-[#1f1f1f];
}
.header.open_banner + main .open_banner_padding {
  @apply pt-[129px] md:pt-[114px] lg:pt-[20px];
}
.header .banner-opener img {
  transition: all 0.3s;
  transform: rotateX(-180deg);
}
.header.open_banner .banner-opener img {
  transform: rotateX(0deg);
}
.header.open_banner {
  @apply max-h-[205px] md:max-h-[303px] lg:max-h-[380px] xl:max-h-[225px] 2xl:max-h-[245px] 2.5xl:max-h-[275px];
}
.header.open_banner + main > div {
  @apply transition-all duration-300 mt-[6rem] md:mt-[13rem] lg:mt-[24rem] xl:mt-[14rem] 2xl:mt-[15rem] 2.5xl:mt-[17rem];
}
.banner-wrap.open_banner {
  @apply max-h-[1000px] max-w-[370px] mx-auto md:max-w-[initial];
  div {
    @apply p-0;
  }
}
.header__mobile-menu {
  .dropdown-select__selected {
    padding: 0;
  }
}
</style>
