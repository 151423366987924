<template>
  <ModalsAuthorizationAuthModalWrapper>
    <div class="flex flex-col lg:min-w-[736px]">
      <h6
        class="mb-16 text-center font-roboto text-[31px] font-medium leading-[38px]"
      >
        Вход в личный кабинет на портале
        <span class="text-ruby-red-100">“История.рф”</span>
      </h6>
      <form>
        <BaseTheInput
          v-model="formData.email"
          placeholder="Введите ваш email"
          type="email"
          class="mb-6"
          is-redesign-auth
          :error="v$.email.$errors[0]?.$message"
        />
        <div class="flex flex-col items-end">
          <BaseTheInput
            v-model="formData.password"
            placeholder="Введите ваш пароль"
            type="password"
            class="mb-1 w-full"
            is-redesign-auth
            :error="v$.password.$errors[0]?.$message"
          />
        </div>
      </form>
      <BaseTheButtonNew
        text="Продолжить"
        type="secondary"
        class="mt-16 max-h-[64px] w-full justify-center sm:w-full"
        @click="login"
      />
      <BaseTheButtonNew
        text="забыли пароль?"
        class="mb-14 mt-7 max-h-[64px] w-full justify-center sm:w-full"
        @click="modalsStore.showResetPasswordForm()"
      />
    </div>
  </ModalsAuthorizationAuthModalWrapper>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, minLength, required } from '@vuelidate/validators';

import { validationErrors } from '@/assets/data/validateErrors';

const authStore = useAuthStore();
const modalsStore = useModalsStore();
const toast = useToast();

const formData = ref({
  email: null,
  password: null,
});

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(validationErrors.required, required),
      email: helpers.withMessage(validationErrors.email, email),
    },
    password: {
      required: helpers.withMessage(validationErrors.required, required),
      minLength: helpers.withMessage(
        validationErrors.passwordMinLength,
        minLength(8),
      ),
    },
  };
});

const v$ = useVuelidate(rules, formData);

const login = async () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return 0;
  }

  const user = {
    email: formData.value.email,
    password: formData.value.password,
    device_name: 'spa',
  };

  try {
    authStore.login(user);
  } catch (e) {
    toast.error(`${e}`);
  }
};
</script>

<style lang="scss"></style>
