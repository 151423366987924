<template>
  <div class="mt-16 flex min-h-screen flex-1 flex-col lg:mt-0">
    <CommonMobileApplicationFixedBanner
      :is-android="isAndroid"
      :is-ios="isIos"
    />

    <Header :is-android="isAndroid" :is-ios="isIos" />

    <main class="w-full 2.5xl:mx-auto 2.5xl:max-w-[1920px]">
      <div
        :class="[
          noTopMargin ? 'mt-16 lg:mt-20' : 'mt-14',
          {
            'container grid grid-cols-12 gap-[20px] py-[40px] xl:pb-[200px]':
              leftMenuCondition,
          },
        ]"
      >
        <CommonLeftMenu
          v-if="leftMenuCondition"
          class="col-span-2 hidden max-w-[290px] xl:block"
        />
        <div
          :class="{
            'col-span-full max-w-[1530px] xl:col-span-10': leftMenuCondition,
          }"
        >
          <!-- <Nuxt keep-alive /> -->
          <slot />
        </div>
      </div>
    </main>
    <Footer />

    <AudioBar v-if="isFixedAudioBarShown" is-fixed />

    <Transition name="fade-modal">
      <Component :is="shownModal" />
    </Transition>
    <!--
    <CoolLightBox
      :items="$store.getters['lightbox/getImages']"
      :index="$store.getters['lightbox/getImageIndex']"
      @close="$store.dispatch('lightbox/removeLightboxProps')"
    />
 -->
    <CommonScrollToTopButton class="fixed bottom-4 right-4 z-100" />
  </div>
</template>

<script setup>
const EmailAuthForm = resolveComponent('ModalsAuthorizationEmailAuthForm');
const NewPasswordForm = resolveComponent('ModalsAuthorizationNewPasswordForm');
const RegistrationForm = resolveComponent(
  'ModalsAuthorizationRegistrationForm',
);
const ResetPasswordForm = resolveComponent(
  'ModalsAuthorizationResetPasswordForm',
);
const SocialAuthForm = resolveComponent('ModalsAuthorizationSocialAuthForm');
const TheSearch = resolveComponent('ModalsTheSearch');
const AskQuestion = resolveComponent('ModalsAskQuestion');
const FeedbackForm = resolveComponent('ModalsFeedbackForm');

const props = defineProps({
  noTopMargin: {
    type: Boolean,
    default: false,
  },
  leftMenu: {
    type: Boolean,
    default: false,
  },
});
const audioStore = useAudioStore();
const modalsStore = useModalsStore();
const authStore = useAuthStore();
const toast = useToast();

const navigatorAgent = ref(null);
const route = useRoute();
const router = useRouter();
const queryType = computed(() => route.query?.type);
// const queryType = ref(route.query?.type ? route.query?.type : '');

const modalsMap = new Map([
  ['EmailAuthForm', EmailAuthForm],
  ['NewPasswordForm', NewPasswordForm],
  ['RegistrationForm', RegistrationForm],
  ['ResetPasswordForm', ResetPasswordForm],
  ['SocialAuthForm', SocialAuthForm],
  ['TheSearch', TheSearch],
  ['AskQuestion', AskQuestion],
  ['FeedbackForm', FeedbackForm],
]);
const shownModal = computed(() => modalsMap.get(modalsStore.getShownModal));

const isIos = computed(() => {
  return !!navigatorAgent.value?.match(/iPhone|iPad|iPod/i);
});

const isAndroid = computed(() => {
  return !!navigatorAgent.value?.match(/Android/i);
});

const leftMenuCondition = computed(() => {
  return (
    props.leftMenu &&
    ![
      '/',
      '/profile',
      '/profile/bookmarks',
      '/profile/subscriptions',
      '/profile/comments',
      '/profile/results',
    ].includes(route.path)
  );
});

const isFixedAudioBarShown = computed(() => {
  return audioStore.isFixedAudioBarShown;
});

onMounted(async () => {
  await nextTick();
  navigatorAgent.value = navigator.userAgent;
  // если в cookies есть token, берем его, прописываем во vuex и получаем данные пользователя
  const token = authStore.getToken;

  if (token) {
    await authStore.setLoggedIn(token);
  }
  // авторизация через социальные сети
  if (queryType.value === 'social_login') {
    const token = route.query.token;
    if (token) {
      try {
        await authStore.setLoggedIn(token);
        toast.success('Вы успешно авторизовались');
      } catch (e) {
        toast.error(`Возникла ошибка при попытке авторизоваться: ${e}.`);
      }
    }
    await router.push('/');
  }

  // обработка переходов на сайт по ссылке из писем подтверждения почты или восстановления пароля
  if (queryType.value === 'email_confirm') {
    const emailFromUrlParams = route.query.email_verified;
    if (emailFromUrlParams) {
      toast.success('Вы успешно подтвердили свой аккаунт.');
    } else {
      toast.error('Возникла ошибка при подтверждении аккаунта.');
    }
    await router.push('/');
  } else if (queryType.value === 'reset_password') {
    modalsStore.showNewPasswordForm();
  }

  /* TODO
    // vk id
    Config.init({
      appId: 51507449,
    });
    const vkIdPayload = this.$route.query.payload;
    if (vkIdPayload) {
      const vkIdPayloadParsed = JSON.parse(vkIdPayload);
      // IDK why but we need this
      const uuid = JSON.parse(JSON.stringify(vkIdPayloadParsed.uuid));
      const { data } = await api.auth.vkIdAuth({
        token: vkIdPayloadParsed.token,
        uuid,
        user: vkIdPayloadParsed.user,
        referer: this.$route.path,
      });
      const token = data?.token;
      if (token) {
        try {
          await this.$store.dispatch('auth/setLoggedIn', token);
          await this.$showNotification('success', 'Вы успешно авторизовались');
        } catch (e) {
          this.$showNotification(
            'error',
            `Возникла ошибка при попытке авторизоваться: ${e}.`,
          );
        }
      }
      const returnUrl = data?.return_url;
      if (returnUrl) {
        await this.$router.push(returnUrl);
      } else {
        await this.$router.push('/');
      }
    }*/
});
</script>

<style lang="scss"></style>
