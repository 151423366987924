<template>
  <ModalsTheModal is-search>
    <div class="flex h-[52px] w-full items-center bg-white-100">
      <SvgIcon
        name="search"
        class="icon ml-2 mr-3 md:ml-10"
        style="height: 20px; width: 20px"
      ></SvgIcon>
      <form class="w-full" @submit.prevent="handleSearchButtonClick">
        <input
          v-model="searchRequest"
          autofocus
          type="text"
          class="mr-3 w-full pr-3"
          placeholder="Введите поисковой запрос"
        />
      </form>
      <button
        class="ml-auto mr-2 rounded-md bg-ruby-red-100 px-4 py-2 text-[14px] uppercase leading-[17px] text-white-100 md:mr-10"
        @click="handleSearchButtonClick"
      >
        Найти
      </button>
    </div>
  </ModalsTheModal>
</template>

<script setup>
const router = useRouter();
const modalsStore = useModalsStore();

const searchRequest = ref('');
const searchTarget = 'articles';

const handleSearchButtonClick = () => {
  modalsStore.closeModal();
  router.push({
    query: { q: searchRequest.value, model: searchTarget },
    path: '/search',
  });
};
/*
import TheModal from '@/components/Modals/TheModal';

export default {
  components: {
    TheModal,
  },

  data() {
    return {
      searchRequest: '',
      searchTarget: 'articles',
    };
  },
  methods: {
    handleSearchButtonClick() {
      this.$store.dispatch('modal/closeModal');
      this.$router.push({
        query: { q: this.searchRequest, model: this.searchTarget },
        path: '/search',
      });
    },
  },
};*/
</script>

<style lang="scss"></style>
